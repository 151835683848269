/*
 * Side Bar - 프로모션 관리
 */

// assets
import { IconBoxSeam, IconConfetti, IconGift, IconSpeakerphone } from '@tabler/icons';

// ==============================|| PRODUCT MENU ITEMS ||============================== //

const product = {
  id: 'promotionTab',
  title: '프로모션 관리',
  type: 'group',
  only: ['common'], // 나라포스 매장 아닌 일반 매장 사장님만 보임
  children: [
    {
      id: 'promotionManage',
      title: '셀프 프로모션 관리',
      type: 'item',
      url: '/self-promotion',
      icon: IconGift,
      breadcrumbs: true,
      only: ['common'] // 나라포스 매장 아닌 일반 매장 사장님만 보임
    },
    {
      id: '1kmpromotionManage',
      title: '플랫폼 프로모션 관리',
      type: 'item',
      url: '/benefit/promotion',
      icon: IconSpeakerphone,
      breadcrumbs: true
    },
    {
      id: 'todayBottle',
      title: '오늘의 한 병',
      type: 'item',
      url: '/product/today-one-bottle',
      icon: IconConfetti,
      breadcrumbs: true
    },
    {
      id: 'cobuying',
      title: '공동 구매 관리',
      type: 'item',
      url: '/promotion/co-buying',
      icon: IconBoxSeam,
      breadcrumbs: true
    }
    // {
    //   id: 'keymedi2408',
    //   title: '[임시] 키메디 주문',
    //   type: 'item',
    //   url: '/pos/keymedi2408/order-info',
    //   icon: IconClipboardCheck,
    //   breadcrumbs: false,
    //   only: ['narapos'] // 나라포스 매장만 보임
    // }
  ]
};

export default product;
